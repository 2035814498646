.datePickerBox{
    width: 100% !important;
}
.paymentRow{
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
    align-items: center;   
}
.paymentRow h6{
    min-width: 200px;
}
.paymentRow .paymentInput{
    width: 100%;
}