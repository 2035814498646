.checkboxStatus span{
    color: black !important;
}
@media print {
    .exclude-from-print {
        display: none;
    }

    .page-break {
        page-break-before: always;
    }
    .ProfileInfo , .comakerInfo {
        box-shadow: none !important;
    }
    .box-shadow-none {
        box-shadow: none !important;
    }
    
}
.dont-show{
    display: none;
}