.MuiTableCell-head .MuiButton-root{
    font-weight: 600 !important;
}
/* table.css */
.highlight-row {
    border: 2px solid rgb(255, 179, 0);
  }
.highlight-row-green{
    border: 2px solid rgb(76, 175, 80);
}
.highlight-row-red{
    border: 2px solid rgb(244, 67, 54);
}