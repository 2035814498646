.btnCaptureImg{
    margin-top:-60px;
}
.btn-container{
    margin-bottom: 10px;
}
.gridLabelTitle{
    background: #D9D9D9;
    
    padding:5px !important;
}
.gridLabelTitle h6{
    text-align: center !important;
    justify-content: center !important;
}
.datePickerBox .MuiFormControl-root{
    width: 100% !important;
}

input:disabled,textarea:disabled{
    -webkit-text-fill-color: black !important;
    font-weight: 700;
}