.dashboard-container{
    width: 100% !important;
    max-width: 100% !important;
}
.tss-dbu3l1-MUIDataTableViewCol-formGroup{
    padding-left: 25px !important;
}

.tss-1nmvwsz-MUIDataTableViewCol-title{
    margin-top: 15px !important;
    margin-left: 20px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}
.css-ubpweo-MuiPaper-root-MuiPopover-paper{
    margin-left: -80px;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    margin-top: -20px;
    margin-left: -67px;
}